<style scoped></style>

<template>
  <div class="container mt-4 mb-4">
    <div class="row">
      <div class="col-12 mx-auto">
        <h3>Thank you for visiting the Desert Nomads Golf Club</h3>
      </div>
    </div>

    <div class="row  mt-4">
      <div class="col-12">
        <p>
          Welcome to the Desert Nomads Golf Club, formed to provide golfers a fair, fun and easy way to keep a lifetime
          scoring record/handicap and enjoy the game of golf. Its members are golfers who prefer to enjoy all courses
          the Southwest has to offer versus joining a specific course-based club. We put together regular scheduled
          outings, last minute outings and tournaments throughout the year. Our signature event is a four day Ryder Cup
          competition in late Summer known as the
          Slyder Cup.
        </p>
        <p>
          With the help of Internet technology and a strong tournament committee, the founders designed Desert Nomads to
          be the most 'sandbagger' proof golf club in the world. The ease of Internet posting of scores along with an
          extremely dynamic peer review system provide the competition committee statistical information to make
          informed decisions on accepting or adjusting member handicaps for play. This exclusive handicap system adopts
          even more restrictive demands than the USGA that compel
          members to actively participate together to provide accurate and verified scoring. This ensures that all our
          events are setup for fair competition with a higher likelihood of dramatic finishes and a lower likelihood
          sandbagger problems.
        </p>
        <p>Features found within the Desert Nomads Golf Club App, many of which are exclusive:</p>
        <ul>
          <li>
            easy online outing and tournament signup with simple payment of entry fees; </li>
          <li>
            real time tournament scoring w/ leaderboard; </li>
          <li>
            real time revision schedule, post a score, find out your new handicap; </li>
          <li>
            instant production of your real time USGA handicap card at any time; </li>
          <li>
            course database with slope and rating figures with members able to update course conditions and update
            information;
          </li>
          <li>
            the ability to post and broadcast unfilled reserved tee times to encourage members to participate together;
          </li>
          <li>
            extremely dynamic peer review including handicap graphs and additional ability metrics; </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Visitors',
  data: function () {
    return {

    }
  }
}
</script>
